import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import fetchClient from '@src/api/lib/fetch-client';
import { ROUTES } from "@src/constants"
import { menuConfig } from "@src/layouts/config"

interface PaymentMethod {
  id: number
  name: string
  code: string
  description?: string
}

export type ConfigType = {
  footer_code: string
  header_code: string
  meta: string
  customer_keywords: string[]
  total_cart_items: number
  trending_keywords: string[]
  payment_methods: PaymentMethod[]
  flash_sale: {
    id: number
    from: string
    to: string
    isActive: boolean
    isNotStarted: boolean
    isGoingOn: boolean
  }
  collections: Array<{
    id: number
    name: string
    slug: string
  }>
  categories: Array<{
    id: number
    name: string
    slug: string
  }>
  on_sale: boolean
  branches: Array<{
    id: number
    name: string
    address: string
    phone: string
    email: string
    latitude: number
    longitude: number
  }>
  menuListConfig: any[]
  currencyRates: string[]
}

const initialState: ConfigType | null = null

const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    setConfig: (state, action: PayloadAction<any>) => {
      state = action.payload
      state.menuListConfig = generateMenuConfigList(state)
      return state;
    },
    fetchConfig: (state, action: PayloadAction<any>) => {
      const { data } = fetchClient({
        url: "/config",
        method: "GET"
      })
      state = data
      state.menuListConfig = generateMenuConfigList(state)
    },
    updateFlashSale: state => {
      if (state?.flash_sale) {
        const now = new Date().getTime()
        const flashSaleActive =
          state.flash_sale.to && now < new Date(state.flash_sale.to).getTime()
        state.on_sale = state.on_sale || flashSaleActive
        state.flash_sale = {
          ...state.flash_sale,
          isActive: flashSaleActive,
          isNotStarted: now < new Date(state.flash_sale.from).getTime(),
          isGoingOn:
            now > new Date(state.flash_sale.from).getTime() &&
            now < new Date(state.flash_sale.to).getTime()
        }
      }
    }
  }
})

const generateMenuConfigList = config => {
  return menuConfig
    .map(item => {
      if (item.path === ROUTES.COLLECTION && config?.collections?.length > 0) {
        const subMenu = config.collections.map(col => ({
          title: col.name.toUpperCase(),
          path: ROUTES.COLLECTION + "/" + col.slug,
          isActive: true
        }))
        return {
          ...item,
          subMenu,
          isActive: true
        }
      }

      if (item.path === ROUTES.PRODUCT && config?.categories?.length > 0) {
        const subMenu = config.categories.map(cat => ({
          title: cat.name.toUpperCase(),
          path: ROUTES.PRODUCT_CATEGORY + "/" + cat.slug,
          isActive: true,
          key: cat.key
        }))
        subMenu.unshift({
          title: "Tất cả",
          key: "all",
          path: ROUTES.PRODUCT,
          isActive: true
        })
        return {
          ...item,
          subMenu,
          isActive: true
        }
      }

      return item
    })
    .filter(item => item.isActive)
}

export const { setConfig, fetchConfig, updateFlashSale } =
  configSlice.actions
export default configSlice.reducer
