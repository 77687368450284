import { ROUTES } from "@src/constants/routes"

export const SIDE_BAR_CONFIG = [
  {
    id: 1,
    label: "sidebar.order_management",
    iconName: "package",
    path: ROUTES.PROFILE_ORDERS
  },
  {
    id: 2,
    label: "sidebar.notifications",
    iconName: "notification",
    path: ROUTES.PROFILE_NOTIFICATION
  },
  {
    id: 3,
    label: "sidebar.favorite_products",
    iconName: "heart",
    path: ROUTES.PROFILE_FAVORITE_PRODUCTS
  },
  {
    id: 4,
    label: "sidebar.account_info",
    iconName: "setting",
    path: ROUTES.PROFILE_INFO
  },
  {
    id: 5,
    label: "sidebar.address_book",
    iconName: "location",
    path: ROUTES.PROFILE_ADDRESS
  },
  {
    id: 6,
    label: "sidebar.voucher_wallet",
    iconName: "voucher-2",
    path: ROUTES.PROFILE_VOUCHER
  }
]

export const SIDE_BAR_DEVICE_CONFIG = [
  {
    id: 1,
    label: "sidebar.order_management",
    iconName: "package",
    path: ROUTES.DEVICE_ORDERS
  },
  {
    id: 2,
    label: "sidebar.address_book",
    iconName: "location",
    path: ROUTES.DEVICE_ADDRESS
  },
]