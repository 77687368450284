import { ROUTES } from "@src/constants/routes"

export const getClassNameActive = (data, currentPath) => {
  if (!currentPath) {
    return ""
  }

  if (data?.subMenu) {
    const subMenu = data?.subMenu?.find(item =>
      currentPath.startsWith(item.path)
    )
    if (subMenu) {
      return "active"
    }
  }

  if (
    data?.isHome &&
    ["/", "/#", ROUTES.BEST_SELLER, ROUTES.NEW_IN].includes(
      currentPath
    )
  ) {
    return "active"
  }

  if (!data?.isHome && currentPath.includes(data.path)) {
    return "active"
  }
  return ""
}


export const menuConfig = [
  {
    title: "TRANG CHỦ",
    key: "home",
    isHome: true,
    path: ROUTES.HOME_PAGE,
    isActive: true
  },
  {
    title: "COLLECTIONS",
    key: "collection",
    path: ROUTES.COLLECTION,
    isActive: true
  },
  {
    title: "SẢN PHẨM",
    key: "product",
    path: ROUTES.PRODUCT,
    isActive: true
  },
  {
    title: "ON SALE",
    key: "on_sale",
    path: ROUTES.ON_SALE,
    color: '#FF5F67',
    isActive: true
  },
  {
    title: "EVENT",
    key: "event",
    path: ROUTES.EVENT,
    isActive: true
  },
  {
    title: "GIỚI THIỆU",
    key: "introduction",
    path: ROUTES.INTRODUCE,
    isActive: true,
    subMenu: [
      {
        title: "ABOUT US",
        key: "about_us",
        path: ROUTES.ABOUT_US,
        isActive: true
      },
      {
        title: "MEMBERSHIP",
        key: "membership",
        path: ROUTES.MEMBERSHIP,
        isActive: true
      },
      {
        title: "CHI NHÁNH",
        key: "branch",
        path: ROUTES.BRANCH,
        isActive: true
      },
      {
        title: "TUYỂN DỤNG",
        key: "recruitment",
        path: ROUTES.RECRUITMENT,
        isActive: true
      },
      {
        title: "FAQ",
        key: "faq",
        path: ROUTES.FAQ,
        isActive: true
      }
    ]
  },
  {
    title: "BLOG",
    key: "blog",
    path: ROUTES.BLOG,
    isActive: true
  }
]
