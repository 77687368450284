'use client';

import { Provider } from 'react-redux';
import { store } from './store';
import { useRef, useEffect } from 'react';
import type { RootState } from './store';
import { setConfig } from './slices/configSlice';
import { setUser } from './slices/userSlice';
export default function StoreProvider({
  children,
  preloadedState
}: {
  children: React.ReactNode;
  preloadedState?: Partial<RootState>;
}) {
  const storeRef = useRef(store);
  useEffect(() => {
    if (preloadedState?.config) {
      storeRef.current.dispatch(setConfig(preloadedState.config));
    }
    if (preloadedState?.user) {
      storeRef.current.dispatch(setUser(preloadedState.user));
    }
  }, [preloadedState]);
  return <Provider store={storeRef.current} data-sentry-element="Provider" data-sentry-component="StoreProvider" data-sentry-source-file="StoreProvider.tsx">
      {children}
    </Provider>;
}