import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type UserType = {
  id: number
  full_name: string
  email: string
  phone: string
  avatar: string | null
  product_ids: number[]
  is_verified: boolean
} | null

const initialState: UserType = null

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<any>) => {
      return action.payload
    },
    logout: () => null,
    updateFavoriteProducts: (state, action: PayloadAction<number[]>) => {
      if (state) {
        state.product_ids = action.payload
      }
    }
  }
})

export const { setUser, logout, updateFavoriteProducts } = userSlice.actions
const userReducer = userSlice.reducer
export default userReducer
